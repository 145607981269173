<!--
 * @Author: jiang
 * @Date: 2021-06-11 16:12:55
 * @Description: 任务落实
-->
<template>
  <el-form
    ref="form"
    class="g-p-20"
    label-position="top"
    :model="formData"
    :rules="formRules"
  >
    <el-form-item
      label="批复意见内容"
      prop="content"
    >
      <el-input
        v-model="formData.content"
        type="textarea"
        :autosize="{ minRows: 3 }"
      ></el-input>
    </el-form-item>
    <el-form-item label="图片">
      <c-upload-image v-model="formData.images"></c-upload-image>
    </el-form-item>
    <el-form-item
      label="附件"
      prop="media_keys"
      :required="isMedia"
      :error="mediaError"
    >
      <c-upload-media
        v-model="formData.media_keys"
        :default="defaultMedia"
      ></c-upload-media>
    </el-form-item>
    <el-form-item>
      <div class="g-center">
        <b-btn-confirm
          :loading="confirmLoading"
          @click="onSubmit"
        ></b-btn-confirm>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import CUploadImage from '@/components/upload/image'
import CUploadMedia from '@/components/upload/media'

export default {
  components: {
    CUploadImage,
    CUploadMedia,
  },
  props: {
    id: [String, Number],
    data: {
      type: Object,
    },
    isMedia: {
      type: Boolean,
      default: false,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        content: '',
        images: [],
        media_keys: [],
      },
      formRules: {
        content: [{ required: true, message: '必须添加内容', trigger: 'blur' }],
      },
      defaultMedia: [],
      mediaError: '',
    }
  },
  computed: {
    mediaList() {
      return [...this.defaultMedia, ...this.formData.media_keys]
    },
  },
  watch: {
    data: {
      handler(val) {
        if (val) {
          this.formData = this.$tools.copy(val)
          if (this.formData.media) {
            this.defaultMedia = this.formData.media
          }
        }
      },
      immediate: true,
    },
    mediaList: {
      handler() {
        this.handlerValidMedia()
      },
      deep: true,
    },
  },
  methods: {
    handlerValidMedia() {
      if (this.isMedia && this.defaultMedia.length === 0 && this.formData.media_keys.length === 0) {
        this.mediaError = '必须上传附件'
        return false
      } else {
        this.mediaError = ''
        return true
      }
    },
    onSubmit() {
      if (!this.handlerValidMedia()) return
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', this.formData)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.task-container {
  height: 100%;
  padding: 0 15px 15px;
}

.img-box {
  display: flex;

  .img {
    width: 148px;
    height: 148px;
    margin-right: 10px;
  }
}
</style>
