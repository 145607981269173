<template>
  <b-dialog
    v-model="visible"
    title="文件转发"
    width="500px"
    @close="onClose"
  >
    <el-form
      :model="formData"
      :rules="rules"
      label-position="top"
    >
      <el-form-item
        label="传阅人员"
        prop="user_id"
      >
        <b-select-user v-model="formData.user_id"></b-select-user>
      </el-form-item>
      <div style="text-align: right;">
        <b-btn-confirm
          :loading="loading"
          @click="onConfirm"
        ></b-btn-confirm>
      </div>
    </el-form>
  </b-dialog>
</template>

<script>
import BSelectUser from '@/components/select/cascader/user'
import { updateBacklogForward } from '@/api/backlog/index'
export default {
  components: {
    BSelectUser,
  },
  data() {
    return {
      loading: false,
      visible: false,
      backlogId: null,
      formData: {
        user_id: '',
      },
      rules: {
        user_id: [{ required: true, message: '请选择任务落实人员', trigger: 'blur' }],
      },
      resolve: null,
      reject: null,
    }
  },
  methods: {
    open(backlogId) {
      this.formData.user_id = ''
      this.visible = true
      this.backlogId = backlogId

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    onConfirm() {
      updateBacklogForward({
        task_id: this.backlogId,
        user_id: this.formData.user_id,
      })
        .then(res => {
          this.$message.success('任务转发成功')
          this.resolve()
        })
        .catch(() => {
          this.reject()
        })
    },
    onClose() {
      this.reject()
    },
  },
}
</script>
